/* istanbul ignore file */
import React from 'react';
import { batch } from 'react-redux';
import type { CookieObj } from '@noths/polaris-client-gdpr-compliance';
import { CookieModal, getCookieConsent } from '@noths/polaris-client-gdpr-compliance';
import { GTMWithDataLayer } from '@noths/polaris-client-google-analytics';
import type { DeliveryZoneCode } from '@noths/polaris-client-localisation';
import { parseLocaleSetCookieHeaders } from '@noths/polaris-client-localisation';
import type { CookieConsent } from '@noths/polaris-client-user-configuration';
import {
  actions as userConfigurationActions,
  getOrSetLoop54UserId,
  getOrSetRichRelevanceSessionId,
} from '@noths/polaris-client-user-configuration';
import { StatusCode } from '@noths/polaris-dev-ts-types';
import type { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import { parse } from 'url';

import { SupportShowInCMSBookmarklet } from 'src/components/atoms/SupportShowInCMSBookmarklet/SupportShowInCMSBookmarklet';
import { LegacyLayoutContainer } from 'src/components/layouts/Legacy/LegacyLayoutContainer';
import { TrustLayout } from 'src/components/layouts/Trust/TrustLayout';
import { ERROR_PRODUCT_NOT_FOUND } from 'src/constants/errors';
import { isProd } from 'src/environment';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions as deliveryZoneOptionsActions } from 'src/redux/deliveryZoneOptions';
import { requestProductDetails } from 'src/redux/product';
import type { AppThunkDispatch } from 'src/redux/store';
import { getStore } from 'src/redux/store';
import { fetchGlobalContent } from 'src/services/contentstack';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
import { Layout } from 'src/types/page';
import { getServerSidePageEventObject } from 'src/utils/gaPageEvent';
import { getFeatureFlagsWithMatchers } from 'src/utils/getFeatureFlagsWithMatchers';
import { getProductJSONLDSchema } from 'src/utils/getProductJSONLDSchema';
import { getIsRequestFromExternalReferrer } from 'src/utils/page';

export const CONTENT_SECTION_ID = 'product-details__content';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() as NextConfig;

const { appVersion, version } = publicRuntimeConfig;

function getLayout(variantId: string | undefined): Layout {
  const layoutMapping: { [key: string]: Layout } = {
    standard_layout: Layout.Legacy,
    trust_layout: Layout.Trust,
  };

  return variantId ? layoutMapping[variantId] || Layout.Legacy : Layout.Legacy;
}

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  const pathname = parse(req.url!).pathname!;
  const domain = publicRuntimeConfig.hosts.domain;
  const canonicalUrl = `https://${domain}${pathname}`;
  const externalReferrer = getIsRequestFromExternalReferrer(req, query);

  const { dispatch, getState } = getStore({
    router: { pathname, query },
  });

  const {
    countryCode: initialCountryCode,
    currency: initialCurrency,
    deliveryZone: initialDeliveryZone,
  } = parseLocaleSetCookieHeaders(res.getHeaders());

  const { Loop54User, country_code, currency, delivery_zone, rr_session } = req.cookies;
  const richRelevanceSessionId = getOrSetRichRelevanceSessionId(res, rr_session);
  const cookieConsent: CookieConsent = getCookieConsent(req.cookies as CookieObj);
  const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};

  const countryCode = initialCountryCode ?? country_code;
  const currencyCode = initialCurrency ?? currency;
  const deliveryZone = (initialDeliveryZone ?? delivery_zone) as DeliveryZoneCode;

  batch(() => {
    dispatch(userConfigurationActions.setCountryCode(countryCode));
    dispatch(userConfigurationActions.setCookieConsent(cookieConsent));
    dispatch(userConfigurationActions.setCurrencyCode(currencyCode));
    dispatch(userConfigurationActions.setDeliveryZone(deliveryZone));
    dispatch(userConfigurationActions.setRichRelevanceSessionId(richRelevanceSessionId));
    dispatch(userConfigurationActions.setFeatureFlags(featureFlags));
    dispatch(deliveryZoneOptionsActions.setSelectedCountry(countryCode));
    dispatch(deliveryZoneOptionsActions.setSelectedZone(deliveryZone));

    if (cookieConsent.functionalCookiesConsented) {
      const loop54UserId = getOrSetLoop54UserId(res, Loop54User);
      dispatch(userConfigurationActions.setLoop54UserId(loop54UserId));
    }
  });

  const [, [navData, topBannerData]] = await Promise.all([
    (dispatch as AppThunkDispatch)(
      requestProductDetails({
        cookie: req.headers.cookie,
        healthcheck: req.headers['health-check'] === 'true',
        previewing: !!query.previewing,
        productURI: pathname,
      }),
    ),
    fetchGlobalContent({ featureFlags }),
  ]);

  const state: ReduxApplicationState = getState();
  const {
    deliveryZoneOptions,
    personalisation,
    product,
    productImagesCarousel,
    reviews,
    richRelevance,
    userConfiguration,
  } = state;

  if (product.redirectUrl) {
    return {
      redirect: {
        destination: product.redirectUrl,
        statusCode: StatusCode.MovedPermanently,
      },
    };
  }

  if (product.error) {
    res.statusCode =
      product.error.name === ERROR_PRODUCT_NOT_FOUND
        ? StatusCode.NotFound
        : StatusCode.InternalServerError;

    if (res.statusCode === StatusCode.InternalServerError) {
      res.setHeader('Retry-After', serverRuntimeConfig.retryAfterDuration);
    }
  }
  const layout: Layout = getLayout(featureFlags?.pdp_layout);

  const props: PageProps = {
    layout,
    canonicalUrl,
    navData,
    externalReferrer,
    topBannerData,
    updatedReduxState: {
      // Cherry pick the state to be updated on the client
      deliveryZoneOptions,
      personalisation,
      product,
      productImagesCarousel,
      reviews,
      richRelevance,
      userConfiguration,
    },
  };

  return { props };
};

export default (props: PageProps) => {
  const { canonicalUrl, externalReferrer, layout, updatedReduxState } = props;
  const { product } = updatedReduxState;
  const productJSONLDSchema = getProductJSONLDSchema(product);
  const pageData = JSON.stringify(
    getServerSidePageEventObject(
      version,
      appVersion,
      externalReferrer,
      layout as string,
      updatedReduxState as ReduxApplicationState,
    ),
  );

  return (
    <>
      <Head>
        <title>{`${product.title} By ${product.partner.name}`}</title>
        <meta content={product.metaDescription} name="description" />
        {!product.metaRobotsIndexable && <meta content="noindex,follow" name="robots" />}
        <link href={canonicalUrl} rel="canonical" />
        <script
          dangerouslySetInnerHTML={{ __html: JSON.stringify(productJSONLDSchema) }}
          type="application/ld+json"
        />
        <CookieModal useTestScript={!isProd()} />
      </Head>
      <GTMWithDataLayer pageEventData={pageData} />
      {(!layout || layout === Layout.Legacy) && <LegacyLayoutContainer {...props} />}
      {layout === Layout.Trust && <TrustLayout externalReferrer={externalReferrer} />}
      <SupportShowInCMSBookmarklet productCode={product.code} />
    </>
  );
};
