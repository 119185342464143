import type { CatServiceAPIResponseBody } from 'src/services/cat-service-api/types/CatServiceAPIResponseBody';
import type { ProductState } from '../types';

const partnerLocationIsValid = (location: string) => {
  return location.length > 1 && !/please/i.test(location);
};

// TODO: these omitted properties should not be part of the product Redux slice state
type ProductStateWithoutNonCatServiceProperties = Omit<
  ProductState,
  'error' | 'isQuantityValid' | 'quantity'
>;

export const adaptCatServiceAPIResponseForStore = (
  catServiceAPIResponseBody: CatServiceAPIResponseBody,
): ProductStateWithoutNonCatServiceProperties => {
  const { metaDescription, metaRobotsIndexable, navigation, product } = catServiceAPIResponseBody;
  const { partner } = product;

  return {
    ...{
      ...product,
      partner: {
        ...product.partner,
        location: partnerLocationIsValid(partner.location) ? partner.location : '',
      },
    },
    navigation,
    metaDescription,
    metaRobotsIndexable,
  };
};
