import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import getConfig from 'next/config';

import type { NextConfig } from 'src/types/nextConfig';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const basketAPI = {
  endpoint: publicRuntimeConfig.endpoints.basket,
  name: 'basket-api',
};

export interface GraphQLRequestQueryBody {
  body: {
    query: string;
    variables: unknown;
  };
  headers?: Record<string, string>;
}

export const graphqlRequest = async <ResponseBody, Variables = null>(
  query: string,
  variables?: Variables,
) => {
  const attributes = await getUserAttributes();

  const idToken = attributes?.idToken;

  window.Amplify.Configure.API.REST.addEndpoint(basketAPI);

  try {
    return await window.Amplify.default.API.post<GraphQLRequestQueryBody, ResponseBody>(
      basketAPI.name,
      '/graphql',
      {
        headers: {
          ...(idToken && {
            'x-id-token': idToken,
          }),
        },
        body: {
          query,
          variables,
        },
      },
    );
  } catch (error) {
    const message = error instanceof Error ? error.message : 'unknown error';

    throw new Error(`request to basket-api failed: ${message}`);
  }
};
