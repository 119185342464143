export interface PromoMessage {
  datetimeEnd: string;
  datetimeStart: string;
  eligibleMessage: string;
  eligibleTitle: string;
  promoCode: string;
  qualifiedMessage: string;
  qualifiedTitle: string;
  thresholdCents: number;
}

export const promoMessages: PromoMessage[] = [
  {
    promoCode: 'HURRY10',
    datetimeStart: '2024-12-16T07:00:00.000Z',
    datetimeEnd: '2024-12-18T23:59:59.000Z',
    thresholdCents: 4000,
    eligibleTitle: 'Special offer just for you!',
    eligibleMessage:
      'Get 10% off when you spend £40 or more! Simply apply the code HURRY10 at checkout.',
    qualifiedTitle: "You've done it! Enjoy your 10% off!",
    qualifiedMessage:
      "Congratulations! You've earned 10% off your purchase. Use the promo code HURRY10 at checkout.",
  },
];
