import { type DatePartsMap, formatDate, getOrdinalSuffix } from '@noths/polaris-client-utils';

export const getFormattedDeliveryDate = (date: string) => {
  const { day, month, weekday } = formatDate(date, {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    formatToParts: true,
  }) as DatePartsMap;

  return `${weekday!.value} ${day.value}${getOrdinalSuffix(parseInt(day.value))} ${month.value}`;
};
