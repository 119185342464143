import React from 'react';

import * as styles from './ProductTitleAndPartner.styles';

interface ProductTitleAndPartnerProps {
  onPartnerLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  partnerHref: string;
  partnerLocation: string;
  partnerName: string;
  title: string;
}

export const ProductTitleAndPartner = ({
  onPartnerLinkClick,
  partnerHref,
  partnerLocation,
  partnerName,
  title,
}: ProductTitleAndPartnerProps) => {
  return (
    <div css={styles.root}>
      <h1>{title}</h1>
      <span>
        <span css={styles.nowrapContainer}>Sold by&nbsp;</span>
        <span css={styles.nowrapContainer}>
          <a aria-label={`Sold by ${partnerName}`} href={partnerHref} onClick={onPartnerLinkClick}>
            {partnerName}
          </a>
          &nbsp;
        </span>
        {partnerLocation && <span css={styles.nowrapContainer}>from {partnerLocation}</span>}
      </span>
    </div>
  );
};
