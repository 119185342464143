import type { ComponentType } from 'react';
import React from 'react';
import {
  type IconProps,
  IconTag,
  IconTrophy,
  IconVerifiedBadge,
} from '@noths/polaris-client-ribbons-design-system';

import feefoServiceRatingSvg from 'src/components/molecules/TrustCarousel/feefo-service-rating-4.7.svg';
import { DATE_COMPANY_FOUNDED, getFullYearsBetweenDates } from 'src/utils/getFullYearsBetweenDates';
import { NOTHSLogo } from './NOTHSLogo';
import * as styles from './TrustCarousel.styles';

interface ItemProps {
  Icon: ComponentType<IconProps>;
  heading: string;
  text: string;
  value: string;
}

const TrustItem = ({ Icon, heading, text, value }: ItemProps) => {
  return (
    <li css={styles.item}>
      <h4 css={styles.itemHeader}>
        <span>{value}</span>
        <Icon color="var(--brand-purple-base)" height={32} width={32} />
      </h4>
      <h5>{heading}</h5>
      <p>{text}</p>
    </li>
  );
};

export const TrustCarousel = () => {
  const currentDate = new Date();
  const startDate = new Date(DATE_COMPANY_FOUNDED);
  const years = getFullYearsBetweenDates(startDate, currentDate);

  return (
    <div css={styles.root}>
      <h3>
        We are…
        <NOTHSLogo />
      </h3>
      <div css={styles.scrollable}>
        <ul css={styles.list}>
          <TrustItem
            Icon={IconTrophy}
            heading="Years as a much-loved UK retailer"
            text="And over 1.3 million 5-star product ratings"
            value={String(years)}
          />
          <TrustItem
            Icon={IconVerifiedBadge}
            heading="UK small brands"
            text="Curated by us, loved by you"
            value="5000+"
          />
          <TrustItem
            Icon={IconTag}
            heading="Inspiring products you'll love"
            text="Made or sourced in the UK"
            value="350,000+"
          />
        </ul>
      </div>
      <div css={styles.serviceRating}>
        <h3>Our service is rated Excellent</h3>
        <a href="/reviews">
          <img alt="Feefo rating: 4.7" src={feefoServiceRatingSvg} />
        </a>
      </div>
    </div>
  );
};
