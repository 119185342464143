import React from 'react';
import { visuallyHidden } from '@noths/polaris-client-styles';

import { OVERALL_RATING_HEADER } from 'src/components/molecules/ProductRatingMaxiSummary/copy';
import { StarRating } from 'src/components/molecules/StarRating/StarRating';
import * as styles from './ProductRatingMaxiSummary.styles';

interface ProductRatingMaxiSummaryProps {
  averageRating: number | null;
  numberOfReviews: number;
}

export const ProductRatingMaxiSummary = ({
  averageRating,
  numberOfReviews,
}: ProductRatingMaxiSummaryProps) => {
  if (!averageRating) {
    return null;
  }

  return (
    <div css={styles.overallRatingContainer}>
      <h2 css={styles.ratingHeader}>{OVERALL_RATING_HEADER}</h2>
      <p css={visuallyHidden}>{averageRating} out of 5</p>
      <p aria-hidden="true" css={styles.overallRatingLabel}>
        <span css={styles.overallRating} data-testid="rating">
          {averageRating}
        </span>{' '}
        / 5
      </p>
      <div css={styles.ratingSummary}>
        <StarRating productRating={averageRating} />
        {numberOfReviews} Product review{numberOfReviews > 1 ? 's' : ''}
      </div>
    </div>
  );
};
