import { primaryRegularFont, theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

const logoSize = 40;

export const root = css`
  display: flex;
  column-gap: ${spacing.sm}px;
  align-items: center;
  border-radius: ${spacing.md}px;
  padding: ${spacing.sm}px;
  background-color: var(--brand-purple-5);
  ${primaryRegularFont};
  ${responsiveText.BODY.SM};

  img {
    border-radius: 50%;
    width: ${logoSize}px;
    height: ${logoSize}px;
    object-fit: contain;
    box-shadow: 0 0 4px 4px #1e1e291a;
  }
`;
