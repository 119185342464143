/* istanbul ignore file */

import type { DeliveryOptionInfo } from 'src/types/deliveryZoneOptions';
import { getDateDifferenceInDays } from './getDateDifferenceInDays';

const getMinMaxDeliveryDateInDays = ({
  date,
  exactDate,
  max,
  min,
}: DeliveryOptionInfo): {
  max: number;
  min: number;
} => {
  if (exactDate) {
    const daysFromToday = getDateDifferenceInDays(new Date(), new Date(date!));

    return { min: daysFromToday, max: daysFromToday };
  }

  return { min: min!, max: max! };
};

export const getMinMaxDeliveryEstimate = (
  deliveryOptions: DeliveryOptionInfo[],
): { max: string; min: string } => {
  // Find the largest & smallest delivery estimate in days from the delivery types
  const minDays = deliveryOptions
    ? String(Math.min(...deliveryOptions.map((option) => getMinMaxDeliveryDateInDays(option).min)))
    : 'not set';

  const maxDays = deliveryOptions
    ? String(Math.max(...deliveryOptions.map((option) => getMinMaxDeliveryDateInDays(option).max)))
    : 'not set';

  return { min: minDays, max: maxDays };
};
