import React from 'react';
import { ExpandableCardOffer } from '@noths/polaris-client-ribbons-design-system';

import type { PromoMessageContent } from './getPromoMessageContent';
import * as styles from './PromoMessageBlock.styles';

export interface PromoMessageBlockProps {
  messageContent: PromoMessageContent | null;
}

export const PromoMessageBlock = ({ messageContent }: PromoMessageBlockProps) => {
  if (!messageContent) {
    return null;
  }

  return (
    <div css={styles.root}>
      <ExpandableCardOffer
        offerText={messageContent.message}
        promoCode={messageContent.promoCode}
        title={messageContent.title}
        titleIconType={messageContent.titleIconType}
      />
    </div>
  );
};
