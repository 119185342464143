import React from 'react';
import { SvgIcon } from '@noths/polaris-client-ribbons-design-system';

import { getProductFeatures } from './getProductFeatures';
import * as styles from './ProductFeatures.style';
import type { ProductFeaturesProps } from './types';

interface ProductFeature {
  icon: string;
  label: string;
}

const Item = ({ icon, label }: ProductFeature) => (
  <li css={styles.listItem}>
    <SvgIcon color={'var(--brand-purple-base)'} height={24} src={icon} width={24} />
    <span css={styles.text}>{label}</span>
  </li>
);

export const ProductFeatures = ({
  allowsGiftWrap,
  freeGiftWrap,
  madeInBritain,
  personalisable,
  productRating,
  requiresPhotoUpload,
}: ProductFeaturesProps) => {
  const productFeatures = getProductFeatures({
    allowsGiftWrap,
    freeGiftWrap,
    productRating,
    madeInBritain,
    personalisable,
    requiresPhotoUpload,
  });

  return productFeatures.length > 0 ? (
    <ul css={styles.list}>
      {productFeatures.map((feature, index) => {
        return <Item icon={feature.icon} key={index} label={feature.label} />;
      })}
    </ul>
  ) : null;
};
