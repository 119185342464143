import React from 'react';

import * as styles from './PartnerMiniModule.styles';

interface PartnerMiniModuleProps {
  linkHref: string;
  location: string;
  logoSrc: string;
  name: string;
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const PartnerMiniModule = ({
  linkHref,
  location,
  logoSrc,
  name,
  onLinkClick,
}: PartnerMiniModuleProps) => {
  return (
    <div css={styles.root}>
      <img alt="" src={logoSrc} />
      <span>
        Sold by{' '}
        <b>
          <a href={linkHref} onClick={onLinkClick}>
            {name}
          </a>
        </b>
        <br />
        From <b>{location}</b>
      </span>
    </div>
  );
};
