import type { SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  BasketAPIMoney,
  BasketAPIMutationTrackingData,
} from 'src/services/basket-api/types/BasketAPIResponseBody';
import type { ProductOptionInput } from 'src/types/basket';
import { getClientLogger } from 'src/utils/clientLogger';
import {
  addItemToBasket,
  getBasketForEditing,
  getBasketForViewing,
  updateItemInBasket,
} from './thunks';

export interface BasketState {
  basket: {
    items?: {
      id: string;
      options: ProductOptionInput[];
      quantity: number;
    }[];
    itemsCount?: number;
    itemsTotal?: BasketAPIMoney;
  };
  error: SerializedError | null;
  isPending: boolean;
  trackingData: BasketAPIMutationTrackingData | null;
}

export const initialState: Readonly<BasketState> = {
  basket: {},
  trackingData: null,
  error: null,
  isPending: false,
};

export const { actions, reducer } = createSlice({
  name: 'basket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBasketForViewing.fulfilled, (state, { payload }) => {
        state.basket.itemsTotal = payload.itemsTotal;
      })
      .addCase(getBasketForEditing.fulfilled, (state, { payload }) => {
        state.basket.items = payload.items;
        state.basket.itemsTotal = payload.itemsTotal;
      })
      .addCase(addItemToBasket.pending, (state) => {
        state.error = null;
        state.isPending = true;
      })
      .addCase(addItemToBasket.fulfilled, (state, { payload }) => {
        const { basket, trackingData } = payload;

        state.basket.items = payload.basket.items;
        state.basket.itemsCount = basket.itemsCount;
        state.basket.itemsTotal = basket.itemsTotal;
        state.trackingData = trackingData;
        state.error = null;
        state.isPending = false;
      })
      .addCase(addItemToBasket.rejected, (state, action) => {
        getClientLogger().error(`the addItemToBasket action failed: ${action.error.message}`);

        state.error = action.error;
        state.isPending = false;
      })
      .addCase(updateItemInBasket.pending, (state) => {
        state.error = null;
        state.isPending = true;
      })
      .addCase(updateItemInBasket.fulfilled, (state, { payload }) => {
        state.basket.items = payload.basket.items;
        state.error = null;
        state.isPending = false;
      })
      .addCase(updateItemInBasket.rejected, (state, action) => {
        getClientLogger().error(`the updateItemInBasket action failed: ${action.error.message}`);

        state.error = action.error;
        state.isPending = false;
      });
  },
});
