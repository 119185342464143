const timeFormatRegex = /^\d{2}:\d{2}:\d{2}$/;

export const getFormattedCutoffTime = (isoTime: string) => {
  if (!timeFormatRegex.test(isoTime)) {
    return isoTime;
  }

  const [hoursStr, minutesStr] = isoTime.split(':');
  const hours = parseInt(hoursStr);
  const hour = hours > 12 ? hours - 12 : hours;

  return `${hour}:${minutesStr} ${hours >= 12 ? 'PM' : 'AM'}`;
};
