import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as recommendations from '@noths/polaris-client-recommendations';
import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import { addRUMTiming } from '@noths/polaris-client-utils';
import getConfig from 'next/config';

import type { Placement } from 'src/constants/recommendations';
import { isProdLike } from 'src/environment';
import { requestRecommendedProducts } from 'src/redux/richRelevance';
import { recommendedProductsRequestComplete } from 'src/redux/richRelevance/actions';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import type { NextConfig } from 'src/types/nextConfig';

interface UseRecommendedProductsProps {
  breadcrumbCategoryId: CatServiceAPIProduct['breadcrumbCategoryId'];
  placements: Placement[];
  productCode: CatServiceAPIProduct['code'];
  productTitle: CatServiceAPIProduct['title'];
  richRelevanceSessionId: string | undefined;
}

const { publicRuntimeConfig } = getConfig() as NextConfig;

export const useProductsRecommendations = ({
  breadcrumbCategoryId,
  placements,
  productCode,
  productTitle,
  richRelevanceSessionId,
}: UseRecommendedProductsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserAttributes()
      .then((attributes) =>
        recommendations.initRecommendations({
          apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
          env: isProdLike() ? 'production' : 'qa',
          sessionId: richRelevanceSessionId,
          userId: attributes?.sub,
        }),
      )
      .then(async () => {
        recommendations.setProductDetailPage(productCode, productTitle);
        recommendations.setProductContextCategory(String(breadcrumbCategoryId!));

        const placementData = await recommendations.getRecommendations(placements);

        const recommendationsRequests = placementData.map((placement) =>
          dispatch(requestRecommendedProducts(placement)),
        );

        await Promise.allSettled(recommendationsRequests);
        addRUMTiming('recommendationsLoaded');
      })
      .catch((e) => {
        console.warn(`Could not load recommendations on product details page: ${e.message}`);
      })
      .finally(() => {
        dispatch(recommendedProductsRequestComplete());
      });
  }, []);
};
