import React from 'react';
import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

import { StarRating } from 'src/components/molecules/StarRating/StarRating';
import * as styles from './ProductRatingMiniSummary.styles';

interface ProductRatingMiniSummaryProps {
  averageRating: number;
  linkHref: string;
  numberOfReviews: number;
}

export const ProductRatingMiniSummary = ({
  averageRating,
  linkHref,
  numberOfReviews,
}: ProductRatingMiniSummaryProps) => {
  const handleLinkClick = (ev: React.MouseEvent) => {
    sendGALinkClickEvent(ev, {
      event_action: 'Read reviews click',
      event_category: 'Product reviews',
      event_label: 'not set',
      event: 'custom_event',
    });
  };

  return (
    <a css={styles.starRating} href={linkHref} onClick={handleLinkClick}>
      <StarRating productRating={averageRating} />
      <span css={styles.reviewsText}>
        <b>
          <span css={styles.largeNumber}>{averageRating}</span>/5
        </b>{' '}
        ({numberOfReviews})
      </span>
    </a>
  );
};
