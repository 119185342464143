import { primaryRegularFont, primarySemiBoldFont, theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const root = css`
  margin: 0;
  border: 1px solid var(--brand-purple-70);
  border-radius: ${spacing.xs}px;
  padding: ${spacing.sm}px;

  & + & {
    margin-top: ${spacing.lg}px;
  }

  h6 {
    display: flex;
    justify-content: space-between;
    column-gap: ${spacing.md}px;
    margin: 0 0 ${spacing.base}px;
    ${responsiveText.BODY.MD};

    span {
      ${primaryRegularFont};
    }
  }
`;

export const dateMessage = css`
  margin: 0;
  ${primaryRegularFont};
  ${responsiveText.BODY.SM};

  b,
  time {
    ${primarySemiBoldFont};
  }
`;

export const cutoffTimeMessage = css`
  display: none; // TODO: reinstate as flex when cutoff times take into account date
  column-gap: ${spacing.base}px;
  margin-top: ${spacing.sm}px;
  border: 1px solid var(--brand-bluple-base);
  border-radius: ${spacing.xs}px;
  padding: ${spacing.xs}px;

  span {
    ${responsiveText.BODY.XS};
    ${primarySemiBoldFont};
    color: var(--brand-bluple-base);
  }
`;
