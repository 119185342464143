import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin: 0 0 80px;
`;

export const productTitleAndPartner = css`
  grid-column: span 12;
`;

export const productFeatures = css`
  grid-column: span 12;
`;

export const partnerModules = css`
  grid-column: span 12;
  background-color: var(--brand-purple-5);
  margin-top: ${spacing.xxl}px;
`;
