import React from 'react';
import { grid } from '@noths/polaris-client-ribbons-base';

import { DATE_COMPANY_FOUNDED, getFullYearsBetweenDates } from 'src/utils/getFullYearsBetweenDates';
import * as style from './TrustBanner.style';

export const TrustBanner = () => {
  const currentDate = new Date();
  const startDate = new Date(DATE_COMPANY_FOUNDED);
  const years = getFullYearsBetweenDates(startDate, currentDate);

  const bannerItems = [
    { value: `${years}`, description: 'Years as a UK marketplace' },
    { value: '5000+', description: 'Trusted UK small brands' },
    { value: '350,000+', description: 'Inspiring products you’ll love' },
  ];

  return (
    <div css={[grid, style.wrapper]}>
      <div css={style.container}>
        <h3 css={style.heading}>Reasons to love NOTHS</h3>
        <ul css={style.list}>
          {bannerItems.map((item) => (
            <li css={style.item} key={item.value}>
              <span css={style.term}>{item.value}</span>
              <span css={style.description}>{item.description}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
