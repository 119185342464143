export const DATE_COMPANY_FOUNDED = '2006-04-04';

export const getFullYearsBetweenDates = (startDate: Date, currentDate: Date) => {
  const hasPassedStartDateThisYear =
    currentDate.getMonth() > startDate.getMonth() ||
    (currentDate.getMonth() === startDate.getMonth() &&
      currentDate.getDate() >= startDate.getDate());

  const years = hasPassedStartDateThisYear
    ? currentDate.getFullYear() - startDate.getFullYear()
    : currentDate.getFullYear() - startDate.getFullYear() - 1;

  return years;
};
