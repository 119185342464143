import React from 'react';

import { Star } from 'src/components/atoms/Star/Star';
import { roundProductRating } from 'src/utils/rating';
import * as styles from './starRating.styles';

const starRatings = [1, 2, 3, 4, 5];

export interface StarRatingProps {
  productRating: number | null;
}

export const StarRating = ({ productRating }: StarRatingProps) => {
  if (!productRating) {
    return null;
  }

  return (
    <div aria-label={`${productRating} out of 5`} role="img">
      {starRatings.map((starRating: number) => (
        <span css={styles.starRating} key={`star-${starRating}`}>
          <Star solid={roundProductRating(productRating) >= starRating} />
        </span>
      ))}
    </div>
  );
};
