import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const list = css`
  list-style: none;
  padding: ${spacing.xs}px 0;
  margin: 0;
  border-top: 1px solid var(--brand-purple-30);
  border-bottom: 1px solid var(--brand-purple-30);
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: ${spacing.xs}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      padding: ${spacing.sm}px 0;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding: ${spacing.md}px 0;
    `,
  )}
`;

export const listItem = css`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
  ${responsiveText.BODY.XS};
  font-weight: bold;
  color: var(--brand-bluple-base);
  gap: ${spacing.xs}px;
`;

export const text = css`
  text-align: center;
`;
