import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const container = css`
  grid-column: span 12;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs}px 0;
  gap: ${spacing.xs}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      padding: ${spacing.xs}px ${spacing.xl}px;
    `,
  )}
`;

export const wrapper = css`
  background-color: var(--brand-purple-5);
`;

export const heading = css`
  ${responsiveText.HEADING.XS};
  color: var(--brand-bluple-base);
  margin: 0;
`;

export const list = css`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const item = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const term = css`
  color: var(--brand-purple-base);
  ${responsiveText.HEADING.XS};
  margin-right: ${spacing.xs}px;
  margin-left: ${spacing.lg}px;
  text-align: center;
  font-weight: 500;
`;

export const description = css`
  ${responsiveText.BODY.XS};
  color: var(--brand-bluple-base);
  font-weight: 600;
  margin: 0;
  text-align: center;
`;
