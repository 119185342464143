import { minBreakpoints, primaryRegularFont, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const wrapper = css`
  align-items: center;
  padding-bottom: ${spacing.sm}px;
  grid-row-gap: ${spacing.md}px;
`;

export const imageWrapper = css`
  grid-column: span 4;
  display: block;
  position: relative;
  top: -${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: span 3;
    `,
  )}
`;

export const image = css`
  border-radius: ${spacing.xs}px;
  border: 1px solid var(--brand-purple-10);
  aspect-ratio: 4 / 3;
  width: 100%;
`;

export const content = css`
  grid-column: span 8;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span 4;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: span 6;
    `,
  )}
`;

export const heading = css`
  ${responsiveText.HEADING.XS};
  color: var(--brand-purple-base);
  margin: 0 0 ${spacing.xs}px 0;
  ${primaryRegularFont};
  > a {
    text-decoration: none;
  }
`;

export const location = css`
  color: var(--neutral-black-base);
  display: flex;
  align-items: center;
  gap: ${spacing.xs}px;
  ${responsiveText.BODY.SM};
  margin: 0;
`;

export const buttons = css`
  grid-column: span 12;
  display: flex;
  justify-content: center;
  gap: ${spacing.xl}px;
  align-items: center;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span 4;
      flex-direction: column;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: span 3;
    `,
  )}
`;

export const button = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xs}px;
  padding: ${spacing.xs}px;
  border: 1px solid var(--brand-purple-base);
  background-color: transparent;
  width: 158px;
  height: 30px;
  border-radius: ${spacing.base}px;
  color: var(--brand-purple-base);
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  ${primaryRegularFont};
  ${responsiveText.BODY.SM};
  font-weight: 600;

  &:hover {
    background-color: var(--brand-purple-dark);
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      padding: ${spacing.sm}px;
    `,
  )}
`;
