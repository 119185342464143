import React from 'react';
import { IconSchedule } from '@noths/polaris-client-ribbons-design-system';
import { priceFormatter } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { DeliverySettingsAPIDeliveryOptionCode } from 'src/services/delivery-settings/types/DeliverySettingsAPIDeliveryOptionCode';
import { FREE_DELIVERY } from '../constants/copy';
import { getDeliveryOptionName } from '../utils/getDeliveryOptionName';
import { getFormattedCutoffTime } from '../utils/getFormattedCutoffTime';
import { getFormattedDeliveryDate } from '../utils/getFormattedDeliveryDate';
import * as styles from './DeliveryOption.styles';

export const CUTOFF_TIME_MESSAGE = 'cutoff-time-message';

interface DeliveryOptionProps {
  currency: CurrencyCode;
  cutoffTime?: string;
  date?: string;
  max?: number;
  min?: number;
  optionCode: DeliverySettingsAPIDeliveryOptionCode;
  price: number;
  provider?: string;
}

export const DeliveryOption = ({
  currency,
  cutoffTime,
  date,
  max,
  min,
  optionCode,
  price,
  provider,
}: DeliveryOptionProps) => {
  return (
    <li css={styles.root}>
      <h6>
        <b>{getDeliveryOptionName(optionCode)}</b>
        <span>{price === 0 ? FREE_DELIVERY : priceFormatter(currency, price, false)}</span>
      </h6>
      <p css={styles.dateMessage}>
        {date ? (
          <>
            {optionCode === 'SATURDAY' ? 'On ' : 'Estimated by '}
            <time dateTime={date}>{getFormattedDeliveryDate(date)}</time>
            {provider && (
              <>
                {' via '}
                <b>{provider}</b>
              </>
            )}
          </>
        ) : (
          `${min} - ${max} days from dispatch`
        )}
      </p>
      {cutoffTime && (
        <div css={styles.cutoffTimeMessage} data-testid={CUTOFF_TIME_MESSAGE}>
          <span>
            <IconSchedule color="var(--brand-bluple-base)" height={16} width={16} />
          </span>
          <span>
            Order before <time dateTime={cutoffTime}>{getFormattedCutoffTime(cutoffTime)}</time>
          </span>
        </div>
      )}
    </li>
  );
};
