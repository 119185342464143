import React from 'react';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import {
  IconBasketOutlined,
  IconEmail,
  IconPin,
  Image,
} from '@noths/polaris-client-ribbons-design-system';

import * as copy from './copy';
import * as styles from './PartnerMaxiModule.styles';

interface PartnerMaxiModuleProps {
  location: string;
  logoSrc: string;
  name: string;
  onContactPartnerClick?: () => void;
  onLogoClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onPartnerNameClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onVisitShopClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  partnerLinkHref: string;
}

export const PartnerMaxiModule = ({
  location,
  logoSrc,
  name,
  onContactPartnerClick = () => {},
  onLogoClick = () => {},
  onPartnerNameClick = () => {},
  onVisitShopClick = () => {},
  partnerLinkHref,
}: PartnerMaxiModuleProps) => {
  return (
    <div css={[grid, maxWidthContainer, styles.wrapper]}>
      {logoSrc && (
        <a
          css={styles.imageWrapper}
          href={partnerLinkHref}
          onClick={onLogoClick}
          rel="nofollow"
          tabIndex={-1}
        >
          <Image alt="partner logo" css={styles.image} loading="lazy" src={logoSrc} />
        </a>
      )}
      <div css={styles.content}>
        <h2 css={styles.heading}>
          <a href={partnerLinkHref} onClick={onPartnerNameClick} rel="nofollow">
            {name}
          </a>
        </h2>
        {location && (
          <p css={styles.location}>
            <IconPin color={`var(--neutral-black-base)`} height={16} width={16} /> {location}
          </p>
        )}
      </div>
      <div css={styles.buttons}>
        <a css={styles.button} href={partnerLinkHref} onClick={onVisitShopClick} rel="nofollow">
          <IconBasketOutlined color={`var(--brand-purple-base)`} height={16} width={16} />
          {copy.VISIT_SHOP_CTA}
        </a>
        <button
          css={styles.button}
          onClick={(e) => {
            e.preventDefault();
            onContactPartnerClick!();
          }}
        >
          <IconEmail color={`var(--brand-purple-base)`} height={16} width={16} />
          {copy.CONTACT_PARTNER_CTA}
        </button>
      </div>
    </div>
  );
};
