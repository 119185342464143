import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectAccessibility,
  selectBasket,
  selectDisplayPlacements,
  selectProduct,
  selectSuspended,
  selectUserConfiguration,
} from 'src/redux/selectors';
import { getPartnerHref } from 'src/utils/getPartnerHref';

export const useTrustLayout = (externalReferrer: boolean) => {
  const state = useSelector((state: ReduxApplicationState) => state);
  const accessibility = selectAccessibility(state);
  const basket = selectBasket(state);
  const product = selectProduct(state);
  const userConfiguration = selectUserConfiguration(state);
  const featureFlags = selectFeatureFlags(state);
  const getDisplayPlacements = selectDisplayPlacements();
  const displayPlacements = getDisplayPlacements(state, externalReferrer);
  const suspended = selectSuspended(state);

  return {
    ...displayPlacements,
    pageLiveRegionText: accessibility.statusMessage,
    product,
    partnerHref: getPartnerHref(product.partner.shortcode, featureFlags || {}),
    basketMutationError: Boolean(basket.error),
    richRelevanceSessionId: userConfiguration.richRelevanceSessionId,
    suspended,
  };
};
