import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const root = css`
  grid-column: span 12;

  h3 {
    ${responsiveText.HEADING.SM};
    text-align: center;
  }

  svg {
    display: block;
    margin: ${spacing.sm}px auto;
    width: 270px;
  }
`;

export const scrollable = css`
  overflow-x: auto;
  scrollbar-width: none;
`;

export const list = css`
  display: flex;
  align-items: stretch;
  margin: 0;
  width: 750px;
  padding: 0 0 16px 0;
  column-gap: ${spacing.lg}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      width: 100%;
    `,
  )}
`;

export const item = css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.base}px;
  flex-basis: 33%;
  list-style-type: none;
  margin: 0;
  border: 1px solid var(--brand-purple-10);
  border-radius: ${spacing.xs}px;
  width: 215px;
  padding: ${spacing.md}px;
  box-shadow: 0 8px 16px var(--neutral-black-10);

  h5 {
    margin: 0;
    ${responsiveText.BODY.MD};
    color: var(--brand-bluple-base);
  }

  p {
    margin: 0;
    ${responsiveText.BODY.SM};
    color: var(var(--neutral-black-base));
  }
`;

export const itemHeader = css`
  display: flex;
  justify-content: space-between;
  margin: 0;
  ${responsiveText.HEADING.LG};
  color: var(--brand-purple-base);
`;

export const serviceRating = css`
  text-align: center;

  h3 {
    margin: 0 0 ${spacing.xs}px 0;
    ${responsiveText.HEADING.SM};
  }

  a {
    display: block;
    margin: 0 auto;
    max-width: 270px;
  }

  img {
    display: block;
  }
`;
