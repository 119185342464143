import { connect } from 'react-redux';
import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';

import { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getPartnerHref } from 'src/utils/getPartnerHref';
import { LegacyLayout } from './LegacyLayout';
import type { LegacyLayoutStateProps } from './types';

const mapStateToProps = (state: ReduxApplicationState): LegacyLayoutStateProps => {
  const { accessibility, basket, product, richRelevance, userConfiguration } = state;
  const featureFlags = selectFeatureFlags(state) || {};

  return {
    activeProductsPlacementHasData:
      (richRelevance[Placement.ActiveProducts].products ?? []).length > 0,
    basketMutationError: Boolean(basket.error),
    outOfStockPlacementHasData: (richRelevance[Placement.OutOfStock].products ?? []).length > 0,
    pageLiveRegionText: accessibility.statusMessage,
    partnerHref: getPartnerHref(product.partner.shortcode, featureFlags),
    product,
    recentlyViewedPlacementHasData:
      (richRelevance[Placement.RecentlyViewed].products ?? []).length > 0,
    richRelevanceSessionId: userConfiguration.richRelevanceSessionId,
    similarProductsPlacementHasData:
      (richRelevance[Placement.SimilarProducts].products ?? []).length > 0,
  };
};

export const LegacyLayoutContainer = connect(mapStateToProps)(LegacyLayout);
