import { Placement } from 'src/constants/recommendations';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { isOutOfStock } from 'src/utils/inventoryStatus';

export const getProductsRecommendationsPlacements = (
  inventoryStatus: CatServiceAPIProduct['inventoryStatus'],
  externalReferrer: boolean,
) => {
  return isOutOfStock(inventoryStatus)
    ? [Placement.OutOfStock, Placement.SameSeller, Placement.RecentlyViewed]
    : [
        ...(externalReferrer ? [Placement.SimilarProducts] : []),
        Placement.RecentlyViewed,
        Placement.ActiveProducts,
        Placement.SameSeller,
      ];
};
