import { connect } from 'react-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getPromoMessageContent } from './getPromoMessageContent';
import type { PromoMessageBlockProps } from './PromoMessageBlock';
import { PromoMessageBlock } from './PromoMessageBlock';
import { promoMessages } from './promoMessages';

const mapStateToProps = (state: ReduxApplicationState): PromoMessageBlockProps => {
  const basketTotal = state.basket.basket.itemsTotal?.cents || 0;
  const currency = state.userConfiguration.currencyCode!;
  const messageContent =
    currency === 'GBP' ? getPromoMessageContent(promoMessages, basketTotal) : null;

  return {
    messageContent,
  };
};

export const PromoMessageBlockContainer = connect(mapStateToProps)(PromoMessageBlock);
