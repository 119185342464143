import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const ratingHeader = css`
  margin: 0 0 20px;
  font-size: ${pxToRem(18)};
`;

export const overallRatingContainer = css`
  display: grid;
  align-items: baseline;
  width: 100%;
  padding: ${spacing.xl}px;
  border: 1px solid var(--neutral-black-30);
  border-radius: 0.35rem;
`;

export const overallRating = css`
  font-size: ${pxToRem(32)};
`;

export const overallRatingLabel = css`
  margin: 0 0 8px;
`;

export const ratingSummary = css`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;
