import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { getBasketForEditing, getBasketForViewing } from 'src/redux/basket';
import {
  actions as personalisationActions,
  updatePersonalisationOptionsFromBasketItem,
} from 'src/redux/personalisation';

export const useEditProductPersonalisationOptions = () => {
  const dispatch = useDispatch();
  const { query } = useRouter();

  const retrieveEditItem = async () => {
    dispatch(personalisationActions.updateEditedItem(query.editItem as string));
    await dispatch(getBasketForEditing());
    await dispatch(updatePersonalisationOptionsFromBasketItem());
  };

  useEffect(() => {
    if (query.editItem) {
      retrieveEditItem();
    } else {
      dispatch(getBasketForViewing());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.editItem]);
};
