import type { ExpandableCardOfferProps } from '@noths/polaris-client-ribbons-design-system';

import type { PromoMessage } from './promoMessages';

export interface PromoMessageContent {
  message: string;
  promoCode: string;
  title: string;
  titleIconType: ExpandableCardOfferProps['titleIconType'];
}

export const getPromoMessageContent = (
  promoMessages: PromoMessage[],
  basketTotalCents: number,
  date: Date = new Date(),
): PromoMessageContent | null => {
  const activePromo = promoMessages.find((promoMessage) => {
    const start = new Date(promoMessage.datetimeStart);
    const end = new Date(promoMessage.datetimeEnd);

    return date >= start && date <= end;
  });

  if (activePromo) {
    return basketTotalCents >= activePromo.thresholdCents
      ? {
          message: activePromo.qualifiedMessage,
          promoCode: activePromo.promoCode,
          title: activePromo.qualifiedTitle,
          titleIconType: 'checkmark-circle',
        }
      : {
          message: activePromo.eligibleMessage,
          promoCode: activePromo.promoCode,
          title: activePromo.eligibleTitle,
          titleIconType: 'speaker',
        };
  }

  return null;
};
