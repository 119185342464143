import { getIconPath } from 'src/utils/assetPaths';
import {
  FREE_GIFT_WRAPPING_AVAILABLE,
  GIFT_WRAPPING_AVAILABLE,
  MADE_IN_BRITAIN,
  OUR_CUSTOMERS_LOVE_IT,
  PERSONALISATION_AVAILABLE,
  PHOTO_PERSONALISATION,
} from './copy';
import type { ProductFeaturesProps } from './types';

export const ICON_GIFT = getIconPath('icons-24-gift.svg');
export const ICON_PHOTO = getIconPath('icons-16-photo.svg');
export const ICON_MODE_EDIT = getIconPath('icons-24-mode-edit.svg');
export const ICON_LOCATION_PIN = getIconPath('icons-24-location-pin.svg');
export const ICON_STAR = getIconPath('icons-16-star-fill.svg');

export const getProductFeatures = ({
  allowsGiftWrap,
  freeGiftWrap,
  madeInBritain,
  personalisable,
  productRating,
  requiresPhotoUpload,
}: ProductFeaturesProps) => {
  const lovedByCustomers = productRating !== null && productRating > 4.5;
  // Please keep the elements in the array in this order as this is also the priority order the features will show on the page
  const productFeatures = [
    ...(lovedByCustomers ? [{ label: OUR_CUSTOMERS_LOVE_IT, icon: ICON_STAR }] : []),
    ...(madeInBritain ? [{ label: MADE_IN_BRITAIN, icon: ICON_LOCATION_PIN }] : []),
    ...(requiresPhotoUpload ? [{ label: PHOTO_PERSONALISATION, icon: ICON_PHOTO }] : []),
    ...(!requiresPhotoUpload && personalisable
      ? [{ label: PERSONALISATION_AVAILABLE, icon: ICON_MODE_EDIT }]
      : []),
    ...(allowsGiftWrap
      ? [
          {
            label: freeGiftWrap ? FREE_GIFT_WRAPPING_AVAILABLE : GIFT_WRAPPING_AVAILABLE,
            icon: ICON_GIFT,
          },
        ]
      : []),
  ].slice(0, 3); // Take the first 3 items

  return productFeatures;
};
