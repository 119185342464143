import { selectFavouritesSummary, selectIsFavourite } from '@noths/polaris-client-user-favourites';
import { createSelector } from '@reduxjs/toolkit';

import { Placement } from 'src/constants/recommendations';
import { isSuspended } from 'src/utils/inventoryStatus';
import { getDisplayPageLoadPlacements } from 'src/utils/recommendations';
import type { ReduxApplicationState } from './combinedReducer';

export const selectProduct = (state: ReduxApplicationState) => state.product;

export const selectProductDeliveryByEvent = (state: ReduxApplicationState) =>
  state.product.deliveryByEvent;

export const selectProductDeliveryOptions = (state: ReduxApplicationState) =>
  state.product.deliveryOptions;

export const selectProductPrice = (state: ReduxApplicationState) => state.product.price;

export const selectProductReviews = (state: ReduxApplicationState) => state.reviews;

export const selectProductPartner = (state: ReduxApplicationState) => state.product.partner;

export const selectPersonalisationOptions = createSelector(
  [selectProduct],
  (product) => product.options,
);

export const selectFavouriteProduct = createSelector(
  [selectFavouritesSummary, selectProduct],
  (favouritesSummary, product) => {
    const isFavourited = selectIsFavourite(favouritesSummary, product.code);

    return { ...product, isFavourited };
  },
);

export const selectAccessibility = (state: ReduxApplicationState) => state.accessibility;
export const selectBasket = (state: ReduxApplicationState) => state.basket;
export const selectUserConfiguration = (state: ReduxApplicationState) => state.userConfiguration;

export const selectFeatureFlags = (state: ReduxApplicationState): Record<string, string> =>
  selectFeatureFlags(state) || {};

export const selectSuspended = createSelector([selectProduct], (product) =>
  isSuspended(product.availability, product.available),
);

const selectRichRelevance = (state: ReduxApplicationState) => state.richRelevance;
export const selectPlacementsData = createSelector([selectRichRelevance], (richRelevance) => ({
  activeProductsPlacementHasData:
    (richRelevance[Placement.ActiveProducts].products ?? []).length > 0,
  outOfStockPlacementHasData: (richRelevance[Placement.OutOfStock].products ?? []).length > 0,
  recentlyViewedPlacementHasData:
    (richRelevance[Placement.RecentlyViewed].products ?? []).length > 0,
  similarProductsPlacementHasData:
    (richRelevance[Placement.SimilarProducts].products ?? []).length > 0,
}));

export const selectDisplayPlacements = () =>
  createSelector(
    [
      selectProduct,
      selectSuspended,
      selectPlacementsData,
      (_, externalReferrer: boolean) => externalReferrer,
    ],
    (product, suspended, placementsData, externalReferrer) =>
      getDisplayPageLoadPlacements({
        inventoryStatus: product.inventoryStatus,
        available: !suspended,
        showSimilarProducts: !externalReferrer,
        ...placementsData,
      }),
  );
