import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const starRating = css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  margin-bottom: ${spacing.md}px;
  color: inherit;
  text-decoration: inherit;
`;

export const reviewsText = css`
  ${responsiveText.BODY.XS};

  a:hover & {
    text-decoration: underline;
  }
`;

export const largeNumber = css`
  font-size: 1.5em;
`;
