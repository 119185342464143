import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const starRating = css`
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  margin-right: ${pxToRem(spacing.base)};

  &:nth-child(5) {
    margin-right: ${pxToRem(spacing.xs)};
  }
`;
