import React from 'react';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';

import { PartnerMiniModule } from 'src/components/atoms/PartnerMiniModule/PartnerMiniModule';
import { PartnerMaxiModule } from 'src/components/molecules/PartnerMaxiModule/PartnerMaxiModule';
import { ProductFeatures } from 'src/components/molecules/ProductFeatures/ProductFeatures';
import { TrustBanner } from 'src/components/molecules/TrustBanner/TrustBanner';
import { TrustCarousel } from 'src/components/molecules/TrustCarousel/TrustCarousel';
import { PARTNER_MODULE_ID } from './elementIds';
import { useTrustLayout } from './useTrustLayout';
import * as styles from './TrustLayout.styles';

interface TrustLayoutProps {
  externalReferrer: boolean;
}

export const TrustLayout = ({ externalReferrer }: TrustLayoutProps) => {
  const { partnerHref, product } = useTrustLayout(externalReferrer);

  return (
    <div css={styles.wrapper}>
      <TrustBanner />
      <div css={[grid, maxWidthContainer]}>
        <div css={styles.productTitleAndPartner}>
          <h1>{product.title}</h1>
          <PartnerMiniModule
            linkHref={`#${PARTNER_MODULE_ID}`}
            location={product.partner.location}
            logoSrc={product.partner.logo}
            name={product.partner.name}
          />
        </div>
        <div css={styles.productFeatures}>
          <ProductFeatures
            allowsGiftWrap={product.allowsGiftWrap}
            freeGiftWrap={product.freeGiftWrap}
            madeInBritain={product.specialFeatures.madeInBritain}
            personalisable={product.specialFeatures.personalisable}
            productRating={product.productRating}
            requiresPhotoUpload={product.specialFeatures.requiresPhotoUpload}
          />
        </div>
        <TrustCarousel />
      </div>
      <div css={styles.partnerModules} id={PARTNER_MODULE_ID}>
        <PartnerMaxiModule
          location={product.partner.location}
          logoSrc={product.partner.logo}
          name={product.partner.name}
          partnerLinkHref={partnerHref}
        />
      </div>
    </div>
  );
};
